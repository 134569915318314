import { template as template_efa0a5e240c3408a8b14f57f8030e688 } from "@ember/template-compiler";
const FKLabel = template_efa0a5e240c3408a8b14f57f8030e688(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
