import { template as template_62cd97a47d2e40e5b91336ad07cb6084 } from "@ember/template-compiler";
const FKControlMenuContainer = template_62cd97a47d2e40e5b91336ad07cb6084(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
