import { template as template_b4efe4a51dcf425bbb3b21b4384b67de } from "@ember/template-compiler";
const SidebarSectionMessage = template_b4efe4a51dcf425bbb3b21b4384b67de(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
