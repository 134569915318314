import { template as template_6dfc1b3fcb6545188bdad015424129e4 } from "@ember/template-compiler";
const WelcomeHeader = template_6dfc1b3fcb6545188bdad015424129e4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
